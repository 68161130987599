import React from 'react'
import styled from 'styled-components'
import Logo from "../design_system/themeing/assets/primary-color-logo.svg"
import { HeaderLink } from "../design_system/components/MenuLink"
import {Link} from 'gatsby'
const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin:auto;
    width:100%;
    padding-top:1em;
    
  `

const HeaderItemWrapper = styled.div`
  justify-content: space-between;
  margin: auto;
  flex-grow:0;
  text-align:right;
  `
const HeaderLogo = styled.div`
  flex-grow: 4;
  margin: auto;
  justify-content: space-between;
  text-align: left;
  padding-left:1em;
  `

const Header = () => {
  return (
    <HeaderContainer>
      <HeaderLogo>
        <Link to='/'><img src={Logo} alt= "Link to Home"  /></Link> 
      </HeaderLogo>
      <HeaderItemWrapper>
        <HeaderLink direction="/about">About</HeaderLink>
      </HeaderItemWrapper>
      <HeaderItemWrapper>
        <HeaderLink direction="/contact">Contact</HeaderLink>
      </HeaderItemWrapper>
    </HeaderContainer>
  )
}

export default Header


