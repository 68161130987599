import React from 'react'
import Header from '../page_fragments/Header'
import Footer from '../page_fragments/Footer'
import { GlobalStyle, darkTheme, defaultTheme } from "../design_system/themeing";
import styled, { ThemeProvider } from "styled-components";


const PageContainer = styled.div`
max-width: 800px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
min-height: 100%;
margin: auto;
`
const Content = styled.div`
flex:1;
width:100%
`
const Core = ({ children }) => {



  return (

    <ThemeProvider theme={defaultTheme}>
      <PageContainer>
        <Content>
          {children}
        </Content>
        <Footer />
      </PageContainer>
      
      <GlobalStyle />
    </ThemeProvider>
  )
}

export default Core
