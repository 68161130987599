import React from 'react'
import styled from 'styled-components'
import Logo from '../design_system/themeing/assets/primary-color-logo.svg'
import {Link} from 'gatsby'

    const FooterContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin:auto;
    width:100%;
  `
    const baseAnchor = ({ className, children, direction }) => (
        <a className={className} href={direction}>
            {children}
        </a>
    );
    const StyledAnchor = styled(baseAnchor)`
  color: ${props => props.theme.textColor};
  border-radius: 2px;
  padding: 0 5px;
  transition: background-color 0.2s linear, color 0.2s linear;
  &:hover {
    background-color: ${props => props.theme.primaryHoverColor};
    color: ${props => props.theme.white};
  }
  `
    const FooterItemWrapper = styled.div`
  margin: auto;
  text-align: right;
  flex-grow: 1;
  `
  const FooterLogo = styled.div`
  text-align: left;
  padding-left: 1em;
  padding-bottom: .5em;
  `
  const Footer = () => {
    return (
        <FooterContainer>
            <FooterLogo>
            <Link to='/'><img src={Logo} alt= "Link to Home" /></Link> 
            </FooterLogo>
            
            <FooterItemWrapper>
                <StyledAnchor direction="mailto: justin@justinisbell.org">Email</StyledAnchor>
            </FooterItemWrapper>
            <FooterItemWrapper>
                <StyledAnchor direction="https://www.linkedin.com/in/jbisbell">Linked In</StyledAnchor>
            </FooterItemWrapper>
            <FooterItemWrapper>
                <StyledAnchor direction="https://twitter.com/jslowbell">Twitter</StyledAnchor>
            </FooterItemWrapper>
        </FooterContainer>
    )
}

export default Footer


