export const primary = {
    300: "#1D2347"
  };
  
  export const secondary = {
    100: "#FBCC93",
    300: "#F8A23A"
  };
  
  export const accent = {
    300: "#AD1F23"
  };
  

  
  export const neutral = {
    100: "#ffffff",
    200: "#f4f5f7",
    300: "#e1e1e1",
    400: "#737581",
    500: "#4a4b53",
    600: "#000000"
  };