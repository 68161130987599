import { primary, neutral, secondary, accent} from "./colors";
import { primaryFont } from "./typography";



export const defaultTheme = {
  primaryFont: primaryFont,
  primaryColor: primary[300],
  primaryHoverColor: primary[300],
  primaryActiveColor: primary[300],
  textColorOnPrimary: neutral[400],
  textColor: primary[300],
  dropShadowColor: neutral[300],  
  white: neutral[100],
  accentLink: accent[300]
};