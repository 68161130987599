import styled from "styled-components";
import { typeScale } from "../../index";
import {Link} from 'gatsby'
import React from 'react'

const baseLink = ({ className, children, direction }) => (
    <Link className={className} to={direction}>
      {children}
    </Link>
  );
  
const MenuLink = styled(baseLink)`
  
  border-radius: 2px;
  cursor: pointer;
  font-family: ${props => props.theme.primaryFont};
  transition: background-color 0.2s linear, color 0.2s linear;
  color: ${props => props.theme.textColor};
  padding: 0 5px;
  &:hover {
    background-color: ${props => props.theme.primaryHoverColor};
    color: ${props => props.theme.white};
  }

  &:focus {
    outline: 3px solid ${props => props.theme.primaryHoverColor};
    outline-offset: 2px;
  }

  &:active {
    background-color: ${props => props.theme.primaryActiveColor};
    border-color: ${props => props.theme.primaryActiveColor};
    color: ${props => props.theme.textColorOnPrimary};
  }
`;

export const IndexLink = styled(MenuLink)`
  border: 2px solid transparent;
  font-size: ${typeScale.header1};
  &:disabled {
    background-color: ${props => props.theme.disabled};
    color: ${props => props.theme.textOnDisabled};
    cursor: not-allowed;
  }

`;

export const HeaderLink  = styled(MenuLink)`
border: 2px solid transparent;
font-size: ${typeScale.paragraph};
&:disabled {
  background-color: ${props => props.theme.disabled};
  color: ${props => props.theme.textOnDisabled};
  cursor: not-allowed;
}

`;